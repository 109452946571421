import { parse } from 'date-fns'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { uploadFile } from 'api/file'
import { deleteTaskEstimate } from 'api/tasks'
import { checkIsFieldRequired } from 'helpers/checkIsFieldRequired'
import { formatEstimateTime } from 'helpers/formatEstimateTime'
import formatTimeBlur from 'helpers/formatTimeBlur'
import { getMinutesFromString } from 'helpers/getMinutesFromString'
import { prepareCommonSelects } from 'helpers/prepares'
import { safeQuery } from 'helpers/safeQuery'
import { setDateValue } from 'helpers/setDateValue'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { TASK_TYPE } from 'store/constants'
import { taskTypesSelector, prioritiesSelector } from 'store/dictionaries'
import { taskModalSelector } from 'store/modals'
import { allTasksSelector, projectInfoSelector } from 'store/projects'
import Button from 'ui/components/buttons/Button/'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import { EditorWrapper } from 'ui/components/forms/EditorWrapper/'
import FileUploader from 'ui/components/forms/FileUploader'
import Input from 'ui/components/forms/Input/'
import Select from 'ui/components/forms/Select/'
import SelectWithCheckboxes from 'ui/components/forms/SelectWithCheckboxes'
import { FieldGroup, Buttons, DateItem, UploaderContainer } from '../../styled'
import TaskEstimates from '../TaskEstimates'

const Form = ({
  formik,
  executors,
  pending,
  visibleTaskFields,
  requiredFields,
  setFiles,
  files,
  setConfirmOpened,
}) => {
  const { isManager, isAdmin } = usePrevilegies()
  const taskList = useSelector(allTasksSelector)
  const taskTypes = useSelector(taskTypesSelector)
  const epicsList = useSelector(allTasksSelector)?.filter(
    (i) => i.task_type === TASK_TYPE.Epic
  )

  const projectInfo = useSelector(projectInfoSelector)
  const { eventType, defaultTaskDetails } = useSelector(taskModalSelector)
  const priorities = useSelector(prioritiesSelector)
  const priorityList = prepareCommonSelects(priorities)
  const preparedEpicList = prepareCommonSelects(epicsList, true)

  const preparedTaskList = prepareCommonSelects(
    taskList?.filter((i) => i.id !== defaultTaskDetails?.id),
    true
  )

  const preparedTaskListWithoutEpic = prepareCommonSelects(
    taskList?.filter((i) => i.task_type !== TASK_TYPE.Epic),
    true
  )
  const componentsList = useMemo(() => {
    return projectInfo
      ? prepareCommonSelects(projectInfo.flow.possibleProjectComponents)
      : []
  }, [projectInfo])

  const taskTypesList = useMemo(() => {
    const transformedTypesList =
      taskTypes?.length > 0
        ? prepareCommonSelects(taskTypes).filter(
            (item) => item.id !== TASK_TYPE.Backlog
          )
        : []

    return transformedTypesList
  }, [taskTypes])

  const isNotEpicTask = formik.values.task_type_id?.value != 'Эпик'

  const isNewTask = eventType === 'create'
  const isManagerAdmin = projectInfo?.perm_manager_is_admin && isManager
  const isManagerCanEditEpic = projectInfo?.perm_manager_edit_epic

  const canEditEpic = !defaultTaskDetails?.epic?.id

  const checkEpicIsDisabled = () => {
    if (canEditEpic || isAdmin || isManagerAdmin || isManagerCanEditEpic) {
      return false
    }
    return true
  }

  const sendFileHandler = (fileList, updateFiles) => {
    const fd = new FormData()
    fileList.forEach((file) => {
      fd.append('file[]', file)
    })
    uploadFile(fd).then(({ data }) => {
      if (data) {
        setFiles([...files, ...data])
        updateFiles(data)
      }
    })
  }

  const deleteFileHandler = (fileId) => {
    setFiles(() => files.filter((file) => file.id !== fileId))
  }

  const setPercentageValues = (value) => {
    const number = getMinutesFromString(value)

    if (!number) {
      return
    }

    const test_percentage = (number * projectInfo.test_percentage) / 100
    const management_percentage =
      (number * projectInfo.management_percentage) / 100

    if (test_percentage) {
      formik.setFieldValue(
        'estimate_cost_test',
        formatEstimateTime(String(test_percentage / 60))
      )
    }

    if (management_percentage) {
      formik.setFieldValue(
        'estimate_cost_management',
        formatEstimateTime(String(management_percentage / 60))
      )
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {(visibleTaskFields?.includes('name') || isNewTask) && (
        <Input
          required={true}
          id="editNameInput"
          label="Название"
          name="name"
          meta={formik.getFieldMeta('name')}
          {...formik.getFieldProps('name')}
        />
      )}
      <FieldGroup count={3}>
        {(visibleTaskFields?.includes('task_type') || isNewTask) && (
          <Select
            id="editTypeSelect"
            required={checkIsFieldRequired('task_type', requiredFields)}
            options={taskTypesList}
            value={
              formik.getFieldMeta('task_type_id')
                ? formik.getFieldMeta('task_type_id').value
                : undefined
            }
            meta={formik.getFieldMeta('task_type_id')}
            error={
              formik.getFieldMeta('task_type_id').touched &&
              formik.getFieldMeta('task_type_id').error
            }
            onChange={(newValue) => {
              if (newValue) {
                formik.setFieldValue('task_type_id', newValue)
              } else {
                formik.setFieldValue('task_type_id', '')
              }
            }}
            label="Тип задачи"
            defaultTitle="Любой"
            maxHeight="174px"
          />
        )}
        {(visibleTaskFields?.includes('component') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editComponentSelect"
              required={checkIsFieldRequired('component_id', requiredFields)}
              options={componentsList}
              value={
                formik.getFieldMeta('component_id')
                  ? formik.getFieldMeta('component_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('component_id')}
              error={
                formik.getFieldMeta('component_id').touched &&
                formik.getFieldMeta('component_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('component_id', newValue)
                } else {
                  formik.setFieldValue('component_id', '')
                }
              }}
              label="Компонент"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('users') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editExecutorsSelect"
              // isDisabled={!canAssignWorkers}
              required={checkIsFieldRequired('executors', requiredFields)}
              options={executors}
              value={
                formik.getFieldMeta('executors')
                  ? formik.getFieldMeta('executors').value
                  : undefined
              }
              meta={formik.getFieldMeta('executors')}
              error={
                formik.getFieldMeta('executors').touched &&
                formik.getFieldMeta('executors').error
              }
              onChange={(newValue) => {
                const newExecutors = newValue || []
                formik.setFieldValue('executors', newExecutors)
                // Удаление связанных оценок при удалении ответственных
                const estimates = formik.getFieldMeta('estimates').value ?? []
                if (estimates.length > 0) {
                  const removedEstimates = estimates.filter((estimate) =>
                    newExecutors.every(
                      (newExecutor) => newExecutor.id != estimate.user_id
                    )
                  )
                  if (removedEstimates.length > 0) {
                    ;(async () => {
                      const removedEstimatesToUpdate = []
                      for (let removedEstimate of removedEstimates) {
                        const result = removedEstimate.id
                          ? await safeQuery(() =>
                              deleteTaskEstimate(removedEstimate.id)
                            )
                          : true
                        if (result) {
                          removedEstimatesToUpdate.push(removedEstimate)
                        }
                      }
                      if (removedEstimatesToUpdate.length > 0) {
                        const newEstimates = estimates.filter((estimate) =>
                          removedEstimatesToUpdate.every(
                            (removedEstimate) =>
                              removedEstimate.user_id != estimate.user_id
                          )
                        )
                        formik.setFieldValue('estimates', newEstimates)
                      }
                    })()
                  }
                }
              }}
              label="Ответственный"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
      </FieldGroup>
      <FieldGroup count={3}>
        {(visibleTaskFields?.includes('priority') || isNewTask) && (
          <Select
            id="editPrioritySelect"
            required={checkIsFieldRequired('priority_id', requiredFields)}
            options={priorityList}
            value={
              formik.getFieldMeta('priority_id')
                ? formik.getFieldMeta('priority_id').value
                : undefined
            }
            meta={formik.getFieldMeta('priority_id')}
            error={
              formik.getFieldMeta('priority_id').touched &&
              formik.getFieldMeta('priority_id').error
            }
            onChange={(newValue) => {
              if (newValue) {
                formik.setFieldValue('priority_id', newValue)
              } else {
                formik.setFieldValue('priority_id', '')
              }
            }}
            label="Приоритет"
            defaultTitle="Любой"
            maxHeight="174px"
          />
        )}

        {visibleTaskFields?.includes('estimate_cost') && isNotEpicTask && (
          <Input
            id="editEstimateCostInput"
            label="Оценка по смете"
            name="estimate_cost"
            meta={formik.getFieldMeta('estimate_cost')}
            {...formik.getFieldProps('estimate_cost')}
            onBlur={(event) => {
              setPercentageValues(event?.target?.value)
              formatTimeBlur(event?.target?.value, formik, 'estimate_cost')
            }}
          />
        )}

        {(isAdmin || isManager) && (
          <DateItem>
            <DatepickerInput
              id="timePicker"
              autoComplete="off"
              label="Дедлайн"
              placeholderText="Дедлайн"
              meta={formik.getFieldMeta('deadline')}
              isClearable
              selected={
                formik.getFieldMeta('deadline').value
                  ? parse(
                      formik.getFieldMeta('deadline').value,
                      'dd.MM.yyyy',
                      new Date()
                    )
                  : undefined
              }
              onChange={(date) => setDateValue(formik, 'deadline', date)}
              instanceId="datepicker"
            />
          </DateItem>
        )}
      </FieldGroup>

      {isNotEpicTask && (isAdmin || isManager) && (
        <FieldGroup count={3}>
          <Input
            label="Оценка менеджмента по смете"
            name="estimate_cost_management"
            meta={formik.getFieldMeta('estimate_cost_management')}
            {...formik.getFieldProps('estimate_cost_management')}
            onBlur={(event) =>
              formatTimeBlur(
                event?.target?.value,
                formik,
                'estimate_cost_management'
              )
            }
          />
          <Input
            label="Оценка тестирования по смете"
            name="estimate_cost_test"
            meta={formik.getFieldMeta('estimate_cost_test')}
            {...formik.getFieldProps('estimate_cost_test')}
            onBlur={(event) =>
              formatTimeBlur(event?.target?.value, formik, 'estimate_cost_test')
            }
          />
        </FieldGroup>
      )}

      {isNotEpicTask && <TaskEstimates executors={executors} />}

      {(visibleTaskFields?.includes('description') || isNewTask) && (
        <EditorWrapper
          editorVersion={defaultTaskDetails?.editor_version || 2}
          required={checkIsFieldRequired('description', requiredFields)}
          label="Введите текст"
          name="description"
          meta={formik.getFieldMeta('description')}
          value={formik.getFieldProps('description').value}
          onChange={(value) => {
            formik.setFieldValue('description', value)
          }}
        />
      )}
      {eventType !== 'edit' && (
        <UploaderContainer>
          <FileUploader
            id="descriptionFile"
            items={files}
            smallPreview={true}
            showUploader={true}
            deleteFileHandler={deleteFileHandler}
            sendFileHandler={sendFileHandler}
          />
        </UploaderContainer>
      )}
      <FieldGroup count={4} css="margin-bottom: 15px;">
        {(visibleTaskFields?.includes('block') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editBlockSelect"
              isClearable={true}
              options={preparedTaskList}
              value={
                formik.getFieldMeta('block')
                  ? formik.getFieldMeta('block').value
                  : undefined
              }
              meta={formik.getFieldMeta('block')}
              error={
                formik.getFieldMeta('block').touched &&
                formik.getFieldMeta('block').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('block', newValue)
                } else {
                  formik.setFieldValue('block', '')
                }
              }}
              label="Блокирует задачу"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('related') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editRelatedSelect"
              isClearable={true}
              options={preparedTaskListWithoutEpic}
              value={
                formik.getFieldMeta('related')
                  ? formik.getFieldMeta('related').value
                  : undefined
              }
              meta={formik.getFieldMeta('related')}
              error={
                formik.getFieldMeta('related').touched &&
                formik.getFieldMeta('related').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('related', newValue)
                } else {
                  formik.setFieldValue('related', '')
                }
              }}
              label="Связана с"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('release') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editReleaseSelect"
              isClearable={true}
              options={preparedTaskList}
              value={
                formik.getFieldMeta('release_id')
                  ? formik.getFieldMeta('release_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('release_id')}
              error={
                formik.getFieldMeta('release_id').touched &&
                formik.getFieldMeta('release_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('release_id', newValue)
                } else {
                  formik.setFieldValue('release_id', '')
                }
              }}
              label="В релизе"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('epic') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editEpicSelect"
              isClearable={isAdmin || isManager || isNewTask}
              options={preparedEpicList}
              isDisabled={checkEpicIsDisabled()}
              value={
                formik.getFieldMeta('epic_id')
                  ? formik.getFieldMeta('epic_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('epic_id')}
              error={
                formik.getFieldMeta('epic_id').touched &&
                formik.getFieldMeta('epic_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('epic_id', newValue)
                } else {
                  formik.setFieldValue('epic_id', '')
                }
              }}
              label="Относится к эпику"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
      </FieldGroup>
      <FieldGroup count={3}>
        {(visibleTaskFields?.includes('layout_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editLayoutLinkInput"
              label="Layout Link"
              name="layout_link"
              meta={formik.getFieldMeta('layout_link')}
              {...formik.getFieldProps('layout_link')}
            />
          )}
        {(visibleTaskFields?.includes('markup_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editMarkupLinkInput"
              label="Markup Link"
              name="markup_link"
              meta={formik.getFieldMeta('markup_link')}
              {...formik.getFieldProps('markup_link')}
            />
          )}
        {(visibleTaskFields?.includes('dev_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editDevLinkInput"
              label="Dev Link"
              name="dev_link"
              meta={formik.getFieldMeta('dev_link')}
              {...formik.getFieldProps('dev_link')}
            />
          )}
      </FieldGroup>
      {/* {(visibleTaskFields?.includes('grade_items') || isNewTask) && (
      <TaskGradeItems formik={formik} />
    )} */}
      <Buttons>
        <Button title="Сохранить" type="submit" loading={pending} />
        <Button
          title="Отменить"
          theme="borderGrey"
          type="reset"
          onClick={() => setConfirmOpened(true)}
        />
      </Buttons>
    </form>
  )
}

export default Form

import { addDays, parse } from 'date-fns'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { setDateValue } from 'helpers/setDateValue'
import { ProjectType } from 'store/constants'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import Select from 'ui/components/forms/Select/'
import { DateField } from './styled'

const currentDate = new Date()

export const ProjectTypeField = ({ projectTypeOptions }) => {
  const formik = useFormikContext()
  const startDateHasValue = Boolean(formik.getFieldMeta('begin').value)
  const projectType = formik.getFieldMeta('project_type').value
  const isFixProjectType = ProjectType.Fix === projectType.id

  useEffect(() => {
    if (!startDateHasValue) {
      formik.setFieldValue('end', null)
    }
  }, [startDateHasValue])

  return (
    <>
      <Select
        required={true}
        options={projectTypeOptions}
        value={
          formik.getFieldMeta('project_type')
            ? formik.getFieldMeta('project_type').value
            : null
        }
        meta={formik.getFieldMeta('project_type')}
        error={
          formik.getFieldMeta('project_type').touched &&
          formik.getFieldMeta('project_type').error
        }
        onChange={(newValue) => {
          if (newValue) {
            formik.setFieldValue('project_type', newValue)
          } else {
            formik.setFieldValue('project_type', '')
          }
        }}
        label="Тип проекта"
        id="projectTypeSelect"
        defaultTitle="Любой"
        maxHeight="174px"
        placeholder="Не выбран"
        noMargin
      />
      {isFixProjectType && (
        <>
          <DateField>
            <DatepickerInput
              label="Дата начала"
              isClearable
              disableWeekends
              meta={formik.getFieldMeta('begin')}
              // minDate={currentDate}
              selected={
                formik.getFieldMeta('begin').value
                  ? parse(
                      formik.getFieldMeta('begin').value,
                      'dd.MM.yyyy',
                      currentDate
                    )
                  : null
              }
              onChange={(date) => setDateValue(formik, 'begin', date)}
              instanceId="datepickerStart"
            />
          </DateField>
          <DateField>
            <DatepickerInput
              label="Дата завершения"
              disabled={!startDateHasValue}
              isClearable
              disableWeekends
              minDate={
                formik.getFieldMeta('begin').value
                  ? addDays(
                      parse(
                        formik.getFieldMeta('begin').value,
                        'dd.MM.yyyy',
                        currentDate
                      ),
                      1
                    )
                  : null
              }
              meta={formik.getFieldMeta('end')}
              selected={
                formik.getFieldMeta('end').value
                  ? parse(
                      formik.getFieldMeta('end').value,
                      'dd.MM.yyyy',
                      currentDate
                    )
                  : null
              }
              onChange={(date) => setDateValue(formik, 'end', date)}
              instanceId="datepickerEnd"
            />
          </DateField>
        </>
      )}
    </>
  )
}

import * as Yup from 'yup'
import { ValidationPhrase } from 'helpers/constants'
import { ProjectType } from 'store/constants'

const numberReg = /^[0-9.,]+$/

export const validationSchema = Yup.object({
  name: Yup.string().required(ValidationPhrase.required),
  slug: Yup.string()
    .min(3, 'Символьный код должен содержать минимум 3 символа')
    .matches(/^[a-zA-Z0-9\-\_]+$/i, 'Используется недопустимый символ')
    .required(ValidationPhrase.required),
  workflow: Yup.object().required(ValidationPhrase.required),
  project_type: Yup.object().required(ValidationPhrase.required),
  begin: Yup.string().when('project_type', {
    is: (type) => [ProjectType].includes(type?.id),
    then: Yup.string().nullable(true).required(ValidationPhrase.required),
    otherwise: Yup.string().nullable(true).notRequired(),
  }),
  end: Yup.string().when('project_type', {
    is: (type) => [ProjectType].includes(type?.id),
    then: Yup.string().nullable(true).required(ValidationPhrase.required),
    otherwise: Yup.string().nullable(true).notRequired(),
  }),
  test_percentage: Yup.string()
    .matches(numberReg, ValidationPhrase.onlyNumbers)
    .notRequired(),
  management_percentage: Yup.string()
    .matches(numberReg, ValidationPhrase.onlyNumbers)
    .notRequired(),
})

import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'

export const CheckboxesWrapper = styled.div`
  gap: 16px;
  margin: 24px 0;

  @media (min-width: ${BREAKPOINTS.medium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    flex-direction: column;
  }
`

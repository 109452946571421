import { useFormikContext } from 'formik'
import Input from 'ui/components/forms/Input/'

export const ProjectRatio = () => {
  const formik = useFormikContext()

  return (
    <>
      <Input
        label="Процент тестирования"
        name="test_percentage"
        placeholder="Введите процент тестирования"
        meta={formik.getFieldMeta('test_percentage')}
        style={{ marginBottom: 0 }}
        {...formik.getFieldProps('test_percentage')}
      />
      <Input
        label="Процент менеджмента"
        name="management_percentage"
        placeholder="Введите процент менеджмента"
        meta={formik.getFieldMeta('management_percentage')}
        style={{ marginBottom: 0 }}
        {...formik.getFieldProps('management_percentage')}
      />
    </>
  )
}

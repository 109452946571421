import makeRequest from 'api/'
import getFilterParams from 'helpers/getFilterParams'
import reduceEpicParams from 'helpers/reduceEpicParams'
import { notifyError } from 'ui/components/Notify'

const getRequestParams = (data) => {
  const formed = []

  for (let [key, value] of Object.entries(data)) {
    if (key === 'group_id') {
      value.forEach((id) => {
        formed.push(
          Object.values({
            0: [`group[${id}]`],
            1: '1',
          })
        )
      })
    }
  }

  return Object.fromEntries(formed)
}

export const exportReport = async (format, params, url) => {
  const baseParams = {
    'filter[from]': params.from,
    'filter[to]': params.to,
    'filter[overtime]': params.overtime,
  }

  const epicIds = params.epic_id
  const epicIdsParams = reduceEpicParams(epicIds)

  const commonParams = getRequestParams(params)
  const filterParams = getFilterParams(params, [
    'user_id',
    'project_id',
    'stage_id',
    'task_type_id',
    'department_id',
  ])
  const res = await makeRequest({
    method: 'get',
    url: `/report/${url}/export.${format}`,
    params: {
      ...baseParams,
      ...commonParams,
      ...filterParams,
      ...epicIdsParams,
    },
  })
  if (res?.url) {
    window.open(`${res.url}`, '__blank')
  } else {
    const errors = res?.data?.errors || null
    notifyError(Object.values(errors).join(', '))
  }
}

export const getReports = async (type, data) => {
  const baseParams = {
    'filter[from]': data.from,
    'filter[to]': data.to,
  }
  if (type === 'time') {
    baseParams['filter[overtime]'] = data.overtime
  }
  if (
    data.only_overtime &&
    (type === 'weekly-plan' || type == 'weekly-plan-fact')
  ) {
    baseParams['filter[only_overtime]'] = 1
  }
  const commonParams = getRequestParams(data)
  const filterParams = getFilterParams(data, [
    'user_id',
    'project_id',
    'stage_id',
    'manager_id',
    'department_id',
    'task_type_id',
  ])
  if (data.sort) {
    commonParams[`sort[${data.sort.field}]`] = data.sort.direction
  }

  const epicIds = data.epic_id
  const epicIdsParams = reduceEpicParams(epicIds)
  const res = await makeRequest({
    method: 'get',
    url: `/report/${type}`,
    params: {
      ...baseParams,
      ...commonParams,
      ...filterParams,
      ...epicIdsParams,
    },
  })
  return { type: type, report: res }
}

export const getBugsReport = async (projectId) => {
  const res = await makeRequest({
    method: 'get',
    url: `/report/bugs/export.docx`,
    params: {
      project_id: projectId,
    },
  })

  if (res?.url) {
    window.open(`${res.url}`, '__blank')
  } else {
    const errors = res?.data?.errors || null
    notifyError(Object.values(errors).join(', '))
  }
}

import { api } from '../api'
import { getPreparedParams } from './utils'

export const reportsApi = api
  .enhanceEndpoints({
    addTagTypes: ['ReportList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      gerReportDepartment: builder.query({
        providesTags: ['ReportList'],
        query: (params) => {
          return {
            url: `/report/department`,
            method: 'GET',
            params: getPreparedParams(params),
          }
        },
        transformResponse: (response) => response?.data || [],
      }),
    }),
  })

export const { useGerReportDepartmentQuery } = reportsApi

import { CircularProgress } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { Formik, Form } from 'formik'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from 'api/file'
import {
  useGetPassboltFolderQuery,
  useGetFlowQuery,
  useGetProjectInfoQuery,
  useGetProjectTypeQuery,
  useUpdateProjectMutation,
} from 'apiv2'
import { prepareCommonSelects } from 'helpers/prepares'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { getProjectListAction, getProjectUsersAction } from 'store/projects/'
import Button from 'ui/components/buttons/Button/'
import ImageUploader from 'ui/components/forms/ImageUploader/'
import Switch from 'ui/components/forms/Switch'
import Icon from 'ui/components/Icon'
import { notifyError, notifySuccess } from 'ui/components/Notify/'
import Scrollbar from 'ui/components/Scrollbar/'
import SidebarModal from 'ui/components/SidebarModal/'
import SidebarModalLabel from 'ui/components/SidebarModalLabel/'
import UserRoleWidget from 'ui/components/UserRoleWidget/'
import {
  AddHeader,
  AddBody,
  AddInner,
  AddButtonGroup,
} from 'ui/modules/Projects/components/ProjectAdd/styled'
import { LoadingContainer, FormGrid } from './styled'
import { HeaderWrapper } from '../../styled'
import {
  ProjectBaseInfo,
  ProjectTypeField,
  ProjectPermUser,
  ProjectRatio,
} from '../shared'
import { validationSchema } from '../utils'

const ProjectEdit = (props) => {
  const { closeModal, data, onCloseHandler } = props
  const dispatch = useDispatch()
  const [fileId, setFileId] = useState(null)
  const [fileImage, setFileImage] = useState(null)

  const { isAdmin } = usePrevilegies()

  const { data: projectTypeList, isLoading: projectTypeListLoading } =
    useGetProjectTypeQuery()
  const { data: projectInfo, isLoading: projectInfoLoading } =
    useGetProjectInfoQuery(data?.slug)
  const { data: flowList, isLoading: flowListLoading } = useGetFlowQuery()
  const [updateProject, { isLoading }] = useUpdateProjectMutation()
  const { data: passboltData } = useGetPassboltFolderQuery()
  const projectTypeOptions = prepareCommonSelects(projectTypeList)
  const passboltOptions = prepareCommonSelects(passboltData || [])
  const currentFlow = projectInfo?.flow
    ? prepareCommonSelects([projectInfo?.flow])[0]
    : ''
  const currentProjectType = projectInfo?.project_type
    ? prepareCommonSelects([projectInfo?.project_type])[0]
    : ''
  const canEditProjectPerms =
    useMemo(
      () => projectInfo?.capabilities?.includes('edit_project_perms'),
      [projectInfo]
    ) || isAdmin
  const currentPassboltOption = projectInfo?.passbolt_folder_id
    ? passboltOptions?.filter(
        (item) => item.id === projectInfo?.passbolt_folder_id
      )?.[0]
    : ''
  const initialValues = {
    name: projectInfo?.name || '',
    slug: projectInfo?.slug || '',
    wiki_link: projectInfo?.wiki_link || '',
    workflow: currentFlow,
    passbolt_folder: currentPassboltOption,
    project_type: currentProjectType,
    begin: projectInfo?.begin
      ? new Date(projectInfo?.begin).toLocaleDateString('ru-RU')
      : null,
    end: projectInfo?.end
      ? new Date(projectInfo?.end).toLocaleDateString('ru-RU')
      : null,
    test_percentage: projectInfo?.test_percentage || '',
    management_percentage: projectInfo?.management_percentage || '',
    is_archived: projectInfo?.is_archived || false,
    perm_user_self_assign: projectInfo?.perm_user_self_assign
      ? projectInfo?.perm_user_self_assign
      : false,
    perm_user_create_task: projectInfo?.perm_user_create_task
      ? projectInfo?.perm_user_create_task
      : false,
    perm_manager_is_admin: projectInfo?.perm_manager_is_admin
      ? projectInfo?.perm_manager_is_admin
      : false,
    perm_user_to_rft: projectInfo?.perm_user_to_rft
      ? projectInfo?.perm_user_to_rft
      : false,
    perm_manager_create_supertask: !!projectInfo?.perm_manager_create_supertask
      ? !!projectInfo?.perm_manager_create_supertask
      : false,
    perm_epic_required: projectInfo?.perm_epic_required
      ? projectInfo?.perm_epic_required
      : false,
    perm_user_self_estimate: projectInfo?.perm_user_self_estimate
      ? projectInfo?.perm_user_self_estimate
      : false,
    perm_manager_edit_epic: projectInfo?.perm_manager_edit_epic
      ? projectInfo?.perm_manager_edit_epic
      : false,
    is_tech_support: projectInfo?.is_tech_support
      ? projectInfo?.is_tech_support
      : false,
  }

  const uploadImage = (file) => {
    const fd = new FormData()

    fd.append('file[]', file)

    uploadFile(fd).then(({ data }) => {
      setFileId(data[0].id)
    })
  }
  const sendForm = async (values) => {
    // TODO: очистка формы и убрать dispatch
    updateProject({
      slug: projectInfo.slug,
      data: {
        ...values,
        passbolt_folder_id: values?.passbolt_folder.id,
        workflow: values?.workflow.id,
        project_type_id: values?.project_type.id,
        logo_id: fileId || projectInfo?.logo?.id,
      },
    })
      .unwrap()
      .then(() => notifySuccess('Проект успешно изменен'))
      .catch(() => notifyError('Произошла ошибка'))
    //formik.resetForm()
    dispatch(getProjectUsersAction(projectInfo?.slug))
    dispatch(getProjectListAction())
    closeModal()
  }

  if (projectInfoLoading | flowListLoading | projectTypeListLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }

  return (
    <Scrollbar maxHeight="100vh">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          sendForm(values)
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} noValidate>
            <HeaderWrapper>
              <AddHeader title="Добавить проект" {...props} />
              <div onClick={() => closeModal()}>
                <Icon name="close" />
              </div>
            </HeaderWrapper>
            <AddBody>
              <SidebarModalLabel title="Общая информация" />
              <AddInner style={{ paddingTop: '24px' }}>
                <ImageUploader
                  required={true}
                  label="Логотип проекта"
                  fileImage={
                    fileImage
                      ? fileImage
                      : [
                          {
                            dataUrl: projectInfo?.logo?.link,
                          },
                        ]
                  }
                  onUpload={(image) => {
                    uploadImage(image[0].file)
                    setFileImage(image)
                  }}
                />
                <FormGrid>
                  <ProjectBaseInfo flows={flowList} />
                  <ProjectTypeField projectTypeOptions={projectTypeOptions} />
                  <ProjectRatio />
                </FormGrid>
                <Switch
                  label="Архивный проект"
                  checked={formik.getFieldProps('is_archived').value}
                  onChange={() => {
                    formik.setFieldValue(
                      'is_archived',
                      !formik.getFieldProps('is_archived').value
                    )
                  }}
                />
                {canEditProjectPerms && <ProjectPermUser />}
                <UserRoleWidget
                  projectSlug={data?.slug}
                  onCloseHandler={onCloseHandler}
                  users={projectInfo?.users}
                />
              </AddInner>
            </AddBody>
            <AddButtonGroup>
              <AddInner>
                <Button loading={isLoading} title="Сохранить" type="submit" />
                <Button
                  title="Отменить"
                  theme="borderGrey"
                  type="reset"
                  onClick={closeModal}
                />
              </AddInner>
            </AddButtonGroup>
          </Form>
        )}
      </Formik>
    </Scrollbar>
  )
}

export const ProjectEditModal = (props) => {
  return (
    <SidebarModal {...props}>
      {props.isOpened && (
        <ProjectEdit
          fromList={props.fromList}
          data={props.data}
          title={props.headerTitle}
          {...props}
        />
      )}
    </SidebarModal>
  )
}

export const ProjectEditDrawer = (props) => {
  return (
    <SwipeableDrawer anchor="bottom" {...props}>
      {props.isOpened && (
        <ProjectEdit
          fromList={props.fromList}
          data={props.data}
          title={props.headerTitle}
          {...props}
        />
      )}
    </SwipeableDrawer>
  )
}

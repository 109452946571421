import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'

export const LoadingContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const FormGrid = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${BREAKPOINTS.medium}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 16px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin-bottom: 24px;
  }
`

import { format } from 'date-fns'

export const getPreparedParams = (data) => {
  const params = {}

  if (data.department_id?.length > 0) {
    data.department_id.forEach((item, index) => {
      params[`filters[department_id][${index}]`] = item
    })
  }

  if (data.date_start) {
    params['filters[date_start]'] = format(data.date_start, 'yyyy-MM-dd')
  }

  if (data.date_end) {
    params['filters[date_end]'] = format(data.date_end, 'yyyy-MM-dd')
  }

  return params
}
